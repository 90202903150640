import React from "react"
// import { navigate } from "gatsby"
import Layout from "../../components/layout"
// import SEO from "../../components/seo"
// import Spinner from "../../components/Reusable/Spinner"
// import { getTutorBySlug } from "../../services/course"
// import Support from "../../components/support"
import MaintenancePage from "../maintenance"

const AyoSupport = ({ params }) => {
  // const slug = params.slug
  // const [tutor, setTutor] = useState({})
  // const [response, setResponse] = useState(false)
  // const [error, setError] = useState(false)

  // useEffect(() => {
  //   getTutorBySlug(slug)
  //     .then(resp => {
  //       setTutor(resp.data.user)
  //       setResponse(true)
  //     })
  //     .catch(error => {
  //       setError(error.response.status)
  //     })
  // }, [])

  // if (response === false) {
  //   if (error === 404) navigate("/404")
  //   if (error === 500) navigate("/500")
  // }

  // if (response) {
  return (
    <Layout>
      {/* <SEO title={tutor?.display_name} />
        <Support tutor={tutor} /> */}
      <MaintenancePage />
    </Layout>
  )
  // }

  // return (
  //   <div
  //     style={{
  //       width: "100%",
  //       height: "90vh",
  //       display: "flex",
  //       alignItems: "center",
  //       justifyContent: "center",
  //     }}
  //   >
  //     <Spinner />
  //   </div>
  // )
}

export default AyoSupport
